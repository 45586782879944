import { Component } from '@angular/core';
import { DefaultTS } from './SharedComponents/Default';
import { QuoteService } from './Quote/quote.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ClientApp';
  ratingValue: number = 4.9;
  ratingCount: number = 10;
  currentYear = new Date().getFullYear();
  currentRoute: string;
  previousUrl: string;
  constructor(public Default: DefaultTS, private QuoteService: QuoteService, private router: Router) {
    this.currentRoute = "";
    this.previousUrl = "";

    this.router.events.subscribe((event: Event) => {
     // this.previousUrl = this.router.url;
      if (event instanceof NavigationStart) {
        this.previousUrl = this.router.url;
      }  

      if (event instanceof NavigationEnd) {
        this.Default.HideHeader = false;
        this.currentRoute = event.url;
        this.currentRoute = this.currentRoute.replace('/','');
        this.previousUrl = this.previousUrl.replace('/', '');
        if (this.currentRoute.includes('/')) {
          var l_currentRoute = this.currentRoute.split('/');
          if (this.Default.PagesWithoutLogin.indexOf(l_currentRoute[0]) >= 0) {
            this.Default.AddUserTracking(this.currentRoute, this.previousUrl);
            //console.log("currentRoute: " + l_currentRoute[0]);
            //console.log("previousUrl: " + this.previousUrl);
          }
        }else if (this.Default.PagesWithoutLogin.indexOf(this.currentRoute) >= 0) {
          this.Default.AddUserTracking(this.currentRoute, this.previousUrl);
          //console.log("currentRoute: " + this.currentRoute);
          //console.log("previousUrl: " + this.previousUrl);
        }
      }
    });
  }

  ngOnInit() {
    this.QuoteService.setCanonicalURL();
    this.ratingValue = 4.1;
    this.ratingCount = 10;
  }

}
