
<div class="panel panel-default">
  <div class="panel-body">
    <div class="modal-body">
      <div class="ib-display stw100">
        <label style="margin:20px 0 5px 0;">Line Item Type</label>
        <select class="cs-form__field form-control" [(ngModel)]="data.Type">
          <option *ngIf="LineItemType==1" value="1">Credit (Quote Price, Other Charges, Extra Charges etc)</option>
          <option *ngIf="LineItemType==1" value="100">Credit NO VAT (Quote Price, Other Charges, Extra Charges etc)</option>
          <option value="2">Debit (Cash Payment, Bank Deposit etc)</option>
          <option *ngIf="LineItemType==1" value="3">Debit (Write off,Refund Adjustment,Absolute Discount, Other Adjustment etc)</option>
          <option *ngIf="LineItemType==1" value="4">Cancellation / Manual Refund etc</option>
        </select>
      </div>
      <div class="ib-display stw100">
        <label style="margin:20px 0 5px 0;">Title/Ref#</label>
        <input type="text" class="cs-form__field form-control" [(ngModel)]="data.RefID" />
        <span>{{ShowErrors[0]}}</span>
      </div>
      <div class="ib-display stw100">
        <label style="margin:20px 0 5px 0;">Amount</label>
        <input type="text" class="cs-form__field form-control" [(ngModel)]="data.Amount" />
        <span>{{ShowErrors[1]}}</span>
      </div>
      <div class="ib-display stw100">
        <label style="margin:20px 0 5px 0;">Note (Optional)</label>
        <textarea class="cs-form__field form-control" [(ngModel)]="data.Note"></textarea>
      </div>

      <div *ngIf="data.Type == 1" class="ib-display stw100">
        <div class="flex-display" style="margin:15px 0 0 0;">
          <input type="checkbox" [(ngModel)]="data.HideOnInvoice">
          <label style="margin:0 0 0 5px;">Do Not Itemise On Invoice PDF</label>
        </div>
      </div>

      <!--<div class="ib-display stw100"  *ngIf="data.Type==1">
        <div class="flex-display" style="margin:15px 0 0 0;">
          <input type="checkbox" [(ngModel)]="data.MarkPaid">
          <label style="margin:0 0 0 5px;">Mark As Paid(An other transaction worth entered Amount + VAT will be added as debit/paid transaction)</label>
        </div>
      </div>-->
      <div class="ib-display stw100 mt-5">
        <div style="display:flex;justify-content:center;">
          <button class="btn btn-primary" style="width:100px;margin:0 15px 0 0;" (click)="SaveLineItem()">Save</button>
          <button class="btn btn-primary" style="width:100px;" (click)="ClearLineItem()">Clear</button>
        </div>
      </div>
    </div>
  </div>
</div>

