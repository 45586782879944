import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuoteCanDeactivateSerivce } from './Services/QuoteCanDeactivate.service';
import { AuthGuardAdmin } from './SharedComponents/AuthGuardAdmin';
import { AuthGuardSemiAdmin } from './SharedComponents/AuthGuardSemiAdmin';
import { AuthGuardMover } from './SharedComponents/AuthGuardMover';
import { AuthGuardLoggedIn } from './SharedComponents/AuthGuardLoggedIn';

const routes: Routes = [
  //Admin Pages
  { path: 'user', loadChildren: () => import('./ManageCompanyUsers/ManageCompanyUsers.module').then(m => m.ManageCompanyUsersModule), canActivate: [AuthGuardAdmin] },
  { path: 'rates', loadChildren: () => import('./MNVRates/MNVRates.module').then(m => m.MNVRatesModule), canActivate: [AuthGuardAdmin] },
  { path: 'moverrates', loadChildren: () => import('./MNVMoverRates/MNVMoverRates.module').then(m => m.MNVMoverRatesModule), canActivate: [AuthGuardAdmin] },
  { path: 'movertypes', loadChildren: () => import('./MoverTypes/MNVMoverType.module').then(m => m.MNVMoverTypeModule), canActivate: [AuthGuardAdmin] },
  { path: 'vantypes', loadChildren: () => import('./VanTypes/MNVVanType.module').then(m => m.MNVVanTypeModule), canActivate: [AuthGuardAdmin] },
  { path: 'van', loadChildren: () => import('./MNVVan/MNVVan.module').then(m => m.MNVVanModule), canActivate: [AuthGuardAdmin] },
  { path: 'timeslot', loadChildren: () => import('./MNVTimeSlot/MNVTimeSlot.module').then(m => m.MNVTimeSlotModule), canActivate: [AuthGuardAdmin] },
  { path: 'slots', loadChildren: () => import('./MNVSlotsManagement/MNVSlotsManagement.module').then(m => m.MNVSlotsManagementModule), canActivate: [AuthGuardAdmin] },
  { path: 'largevans', loadChildren: () => import('./MNVUseLargeVansForSmall/MNVUseLargeVanForSmall.module').then(m => m.MNVUseLargeVanForSmallModule), canActivate: [AuthGuardAdmin] },
  { path: 'systemconfiguration', loadChildren: () => import('./SystemConfiguration/SystemConfiguration.module').then(m => m.SystemConfigurationModule), canActivate: [AuthGuardAdmin] },
  { path: 'emailtemplate', loadChildren: () => import('./PTEmailTemplate/PTEmailTemplate.module').then(m => m.PTEmailTemplateModule), canActivate: [AuthGuardAdmin] },
  { path: 'generaldata', loadChildren: () => import('./GeneralData/GeneralData.module').then(m => m.GeneralDataModule), canActivate: [AuthGuardAdmin] },
  //Not In Nav-Menu
  { path: 'quotesettings', loadChildren: () => import('./MNVQuoteSetting/MNVQuoteSetting.module').then(m => m.MNVQuoteSettingModule), canActivate: [AuthGuardAdmin] },
  { path: 'quotesettingsunit', loadChildren: () => import('./MNVQuoteSettingUnit/MNVQuoteSettingUnit.module').then(m => m.MNVQuoteSettingUnitModule), canActivate: [AuthGuardAdmin] },
  { path: 'my-quote', loadChildren: () => import('./MyQuote/MyQuote.module').then(m => m.MyQuoteModule), canActivate: [AuthGuardAdmin] },
  { path: 'tierassignment', loadChildren: () => import('./MNVTierAssignment/MNVTierAssignment.module').then(m => m.MNVTierAssignmentModule), canActivate: [AuthGuardAdmin] },
  { path: 'vantimeslot', loadChildren: () => import('./MNVVanTimeSlot/MNVVanTimeSlot.module').then(m => m.MNVVanTimeSlotModule), canActivate: [AuthGuardAdmin] },
  { path: 'questionnaire', loadChildren: () => import('./PostJobQuestion/PostJobQuestion.module').then(m => m.PostJobQuestionModule), canActivate: [AuthGuardAdmin] },
  { path: 'mnvservices', loadChildren: () => import('./MNVServices/MNVServices.module').then(m => m.MNVServicesModule), canActivate: [AuthGuardAdmin] },
  { path: 'service', loadChildren: () => import('./MNVServices/MNVServices.module').then(m => m.MNVServicesModule), canActivate: [AuthGuardAdmin] },
  { path: 'affiliate', loadChildren: () => import('./PTAffiliate/PTAffiliate.module').then(m => m.PTAffiliateModule), canActivate: [AuthGuardAdmin] },
  { path: 'usertransactions', loadChildren: () => import('./PTUserWalletTransaction/PTUserWalletTransaction.module').then(m => m.PTUserWalletTransactionModule), canActivate: [AuthGuardAdmin] },
  { path: 'my-job', loadChildren: () => import('./MyJob/MyJob.module').then(m => m.MyJobModule), canActivate: [AuthGuardAdmin] },
  { path: 'removals/:fromCode/:toCode', loadChildren: () => import('./Removals/Removals.module').then(m => m.RemovalsModule), canActivate: [AuthGuardAdmin] },
  { path: 'removal-listing', loadChildren: () => import('./RemovalListing/RemovalListing.module').then(m => m.RemovalListingModule), canActivate: [AuthGuardAdmin] },
  { path: 'additional-information', loadChildren: () => import('./AdditionalDetails/AdditionalDetails.module').then(m => m.AdditionalDetailsModule), canActivate: [AuthGuardAdmin] },
  { path: 'vanavailability', loadChildren: () => import('./MNVVanAvailability/MNVVanAvailability.module').then(m => m.MNVVanAvailabilityModule), canActivate: [AuthGuardAdmin] },

  //Semi Admin Pages
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'calendar', loadChildren: () => import('./Calendar/TestCalendar/TestCalendar.module').then(m => m.TestCalendarModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'allquotes', loadChildren: () => import('./MNVQuote/MNVQuote.module').then(m => m.MNVQuoteModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'invoicedata', loadChildren: () => import('./MNVInvoicesData/MNVInvoicesData.module').then(m => m.MNVInvoicesDataModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'receiptsdata', loadChildren: () => import('./MNVReceiptsData/MNVReceiptsData.module').then(m => m.MNVReceiptsDataModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'clients', loadChildren: () => import('./MNVClients/MNVClients.module').then(m => m.MNVClientsModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'support', loadChildren: () => import('./PTSupport/PTSupportScript.module').then(m => m.PTSupportScriptModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'location', loadChildren: () => import('./MVLocations/MVLocations.module').then(m => m.MVLocationsModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'pages', loadChildren: () => import('./StaticPages/StaticPages.module').then(m => m.StaticPagesModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'userReview', loadChildren: () => import('./UserReview/UserReview.module').then(m => m.UserReviewModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'mover', loadChildren: () => import('./MNVMover/MNVMover.module').then(m => m.MNVMoverModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'discount', loadChildren: () => import('./PTDiscount/PTDiscount.module').then(m => m.PTDiscountModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'campaign', loadChildren: () => import('./PTCampaign/PTCampaign.module').then(m => m.PTCampaignModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'campaigndata', loadChildren: () => import('./CampaignData/CampaignData.module').then(m => m.CampaignDataModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'holiday', loadChildren: () => import('./MNVHoliday/MNVHoliday.module').then(m => m.MNVHolidayModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'van_availability', loadChildren: () => import('./TimeSlotsAvailability/TimeSlotsAvailability.module').then(m => m.TimeSlotsAvailabilityModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'quote_detail/:jobId', loadChildren: () => import('./QuoteDetail/QuoteDetail.module').then(m => m.QuoteDetailModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'blogarticle', loadChildren: () => import('./MGBlogArticle/MGBlogArticle.module').then(m => m.MGBlogArticleModule), canActivate: [AuthGuardLoggedIn] },
  { path: 'bankholiday', loadChildren: () => import('./MNVBankHoliday/MNVBankHoliday.module').then(m => m.MNVBankHolidayModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'minimumcharge', loadChildren: () => import('./MNVMinimumCharge/MNVMinimumCharge.module').then(m => m.MNVMinimumChargeModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'recentjob', loadChildren: () => import('./MNVRecentJob/MNVRecentJob.module').then(m => m.MNVRecentJobModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'locationmove', loadChildren: () => import('./MNVLocationMove/MNVLocationMove.module').then(m => m.MNVLocationMoveModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'locationstats', loadChildren: () => import('./MNVLocationStats/MNVLocationStats.module').then(m => m.MNVLocationStatsModule), canActivate: [AuthGuardSemiAdmin] },
  { path: 'waconversations', loadChildren: () => import('./WAConversations/WAConversations.module').then(m => m.WAConversationsModule), canActivate: [AuthGuardSemiAdmin] },

  { path: 'marketingscore', loadChildren: () => import('./AdvertisementDashboard/AdvertisementDashboard.module').then(m => m.AdvertisementDashboardModule), canActivate: [AuthGuardSemiAdmin] },

  //Movers Pages
  { path: 'driverdashboard', loadChildren: () => import('./DriverDashboard/DriverDashboard.module').then(m => m.DriverDashboardModule), canActivate: [AuthGuardMover] },
  { path: 'earningsdashboard', loadChildren: () => import('./EarningsDashboard/EarningsDashboard.module').then(m => m.EarningsDashboardModule), canActivate: [AuthGuardMover] },
  { path: 'tippingdashboard', loadChildren: () => import('./TippingDashboard/TippingDashboard.module').then(m => m.TippingDashboardModule), canActivate: [AuthGuardMover] },
  { path: 'moverunavailability', loadChildren: () => import('./MNVMoverUnavailability/MNVMoverUnavailability.module').then(m => m.MNVMoverUnavailabilityModule), canActivate: [AuthGuardMover] },


  //LoggedIn User Pages
  { path: 'myconversations', loadChildren: () => import('./MyConversations/MyConversations.module').then(m => m.MyConversationsModule), canActivate: [AuthGuardLoggedIn] },
  { path: 'setting', loadChildren: () => import('./Settings/Settings.module').then(m => m.SettingsModule), canActivate: [AuthGuardLoggedIn] },
  //Not In Nav-Menu
  { path: 'mywallettransactions', loadChildren: () => import('./MyWalletTransaction/MyWalletTransaction.module').then(m => m.MyWalletTransactionModule), canActivate: [AuthGuardLoggedIn] },


  { path: 'forgotpassword', loadChildren: () => import('./ForgotPassword/ForgotPassword.module').then(m => m.ForgotPasswordModule) },
  { path: 'resetpassword', loadChildren: () => import('./Resetpassword/Resetpassword.module').then(m => m.ResetpasswordModule) },
  //{ path: 'stripe', loadChildren: () => import('./Stripe/Stripe.module').then(m => m.StripeModule) },
  //{ path: 'additional-information-listing', loadChildren: () => import('./AdditionaDetailsListing/AdditionaDetailsListing.module').then(m => m.AdditionaDetailsListingModule) },
  { path: 'test', loadChildren: () => import('./Test/Test.module').then(m => m.TestModule) },

  //{ path: 'Popup', loadChildren: () => import('./Popup/Popup.module').then(m => m.PopupModule) },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        initialNavigation: 'enabled',
        //enableTracing: true, // <-- debugging purposes only
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy'
      }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {
}
