


<div class="map">
  <google-map height="500px"
              width="100%"
              [options]="mapOptions">
    <map-marker [position]="markerPosition"></map-marker>
  </google-map>
</div>
