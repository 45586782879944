import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DefaultTS } from '../SharedComponents/Default';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { QuoteService } from '../Quote/quote.service';
import { json } from 'express';

@Component({
  selector: 'ShowMapMarker',
  templateUrl: './ShowMapMarker.html'
})
export class ShowMapMarker {

  @Input() public latitude;
  @Input() public longitude;

  mapOptions: google.maps.MapOptions = {
    center: { lat: 0, lng: 0 },
    zoomControl: false,
    mapTypeControl: false, 
    streetViewControl: false,
    fullscreenControl: false,
  };

  markerPosition = { lat: 0, lng: 0 };
  ngOnChanges() {
    this.mapOptions.center = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
    this.markerPosition = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
  }
}
