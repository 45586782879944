import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, UrlSerializer } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploaderCustom } from './SharedComponents/FileUploader';
import { SortJsonData } from './Pipes/SortingPipe';
import { FilterJsonData } from './Pipes/FilterPipe';
import { Ordinal } from './Pipes/OrdinalPipe';
import { DefaultTS } from './SharedComponents/Default';
import { RoutingState } from './SharedComponents/RoutingState';
import { LowerCaseUrlSerializer } from './SharedComponents/URLSerializer';
import { Timer } from '../app/SharedComponents/Timer';
import { ShowModal } from './SharedComponents/ShowModal';
import { FormatTimePipe } from './Pipes/FormatTimePipe';
import { ConfirmationPopup } from './ConfirmationPopUp/ConfirmationPopup';
//import { UserRating } from './UserRating/UserRating';

import { SanitizeHtmlPipe } from './Pipes/sanitizeHtml';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { ConversationList } from './ConversationList/ConversationList';
import { Conversation } from './Conversation/Conversation';
import { PTDragDropUpload } from './PTDragDropUpload/PTDragDropUpload';
import { NgSelect2Module } from 'ng-select2';
import { UserRating } from './UserRating/UserRating';
import { RatingModule } from 'ngx-bootstrap/rating';
import { QuoteHeader } from './QuoteHeader/QuoteHeader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ChartsModule  } from 'ng2-charts';
import { QuoteInfo } from './QuoteInfo/QuoteInfo';
import { ServiceInfo } from './ServiceInfo/ServiceInfo';
import { MNVLineItems } from './MNVLineItems/MNVLineItems';
import { UpdatePassword } from './UpdatePassword/UpdatePassword';
import { UpdateMobile } from './UpdateMobile/UpdateMobile';
import { PTMobileInput } from './PTMobileInput/PTMobileInput';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CompleteJob } from './CompleteJob/CompleteJob';
import { AuthGuardAdmin } from './SharedComponents/AuthGuardAdmin';
import { AuthGuardSemiAdmin } from './SharedComponents/AuthGuardSemiAdmin';
import { AuthGuardMover } from './SharedComponents/AuthGuardMover';
import { AuthGuardLoggedIn } from './SharedComponents/AuthGuardLoggedIn';
import { MultiSelectionDropdown } from './MultiSelectionDropdown/MultiSelectionDropdown';
import { AutocompleteComponent } from './Quote/google-places.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { ShowMapMarker } from './ShowMapMarker/ShowMapMarker';

export const maskoptions: Partial<IConfig> = {
  thousandSeparator: ","
};

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(maskoptions),
    BrowserTransferStateModule,
    NgSelect2Module,
    RatingModule,
    AutocompleteLibModule,
    ChartsModule,
    GoogleMapsModule
  ],
  declarations: [
    FileUploaderCustom,
    SortJsonData,
    FormatTimePipe,
    ConfirmationPopup,
    UserRating,
    Ordinal,
    DefaultTS,
    Timer,
    ShowModal,
    SanitizeHtmlPipe,
    ConversationList,
    Conversation,
    PTDragDropUpload,
    QuoteHeader,
    MNVLineItems,
    QuoteInfo,
    ServiceInfo,
    UpdatePassword,
    UpdateMobile,
    PTMobileInput,
    AutocompleteComponent,
    CompleteJob,
    MultiSelectionDropdown,
    ShowMapMarker,
  ],
  exports: [
    RouterModule,
    FileUploaderCustom,
    SortJsonData,
    FormatTimePipe,
    ConfirmationPopup,
    UserRating,
    Ordinal,
    DefaultTS,
    Timer,
    ShowModal,
    SanitizeHtmlPipe,
    NgxIntlTelInputModule,
    BsDropdownModule,
    BrowserTransferStateModule,
    ConversationList,
    Conversation,
    PTDragDropUpload,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module,
    QuoteHeader,
    QuoteInfo,
    ServiceInfo,
    AutocompleteLibModule,
    ChartsModule,
    MNVLineItems,
    NgxMaskModule,
    UpdatePassword,
    UpdateMobile,
    PTMobileInput,
    AutocompleteComponent,
    CompleteJob,
    MultiSelectionDropdown,
    GoogleMapsModule,
    ShowMapMarker
  ],
})
export class SharedDataModule {
  static forRoot(): ModuleWithProviders<SharedDataModule> {
    return {
      ngModule: SharedDataModule,
      providers: [
        FileUploaderCustom,
        AuthGuardAdmin,
        AuthGuardSemiAdmin,
        AuthGuardMover,
        AuthGuardLoggedIn,
        SortJsonData,
        FormatTimePipe,
        ConfirmationPopup,
        UserRating,
        ConversationList,
        Conversation,
        PTDragDropUpload,
        FilterJsonData,
        Ordinal,
        UpdatePassword,
        UpdateMobile,
        PTMobileInput,
        AutocompleteComponent,
        SanitizeHtmlPipe,
        DefaultTS,
        Timer,
        RoutingState,
        NgxIntlTelInputModule,
        NgSelect2Module,
        MultiSelectionDropdown,
        DatePipe,
        {
          provide: UrlSerializer,
          useClass: LowerCaseUrlSerializer,
        },
      ],
    };
  }
}
